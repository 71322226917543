/*
 * @Author: andrew
 * @Date: 2023-02-22 09:35:53
 * @LastEditTime: 2023-02-22 11:42:50
 * @LastEditors: andrew
 * @Description:
 * @FilePath: \nlg_h5\src\main.js
 *
 */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import http from "./http";
import {
  Button,
  NavBar,
  Icon,
  DropdownMenu,
  DropdownItem,
  Search,
  PullRefresh,
  Toast,
  Col,
  Row,
  List,
  Popup,
  Form,
  Field,
  CountDown,
  Picker,
  NumberKeyboard,
  DatetimePicker,
  Dialog,
  Empty,
} from "vant";

Vue.config.productionTip = false;

Vue.prototype.$get = http.get;
Vue.prototype.$post = http.post;
Vue.prototype.$put = http.put;

Vue.use(Col);
Vue.use(Row);
Vue.use(Button);
Vue.use(NavBar);
Vue.use(Icon);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Search);
Vue.use(PullRefresh);
Vue.use(List);
Vue.use(Popup);
Vue.use(Form);
Vue.use(Field);
Vue.use(CountDown);
Vue.use(Picker);
Vue.use(NumberKeyboard);
Vue.use(DatetimePicker);
Vue.use(Dialog);
Vue.use(Toast);
Vue.use(Empty);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
