import Vue from "vue";
import { state } from "vuex";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "list",
    component: () => import("../views/list.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/login.vue"),
  },
  // {
  //   path: "/search",
  //   name: "search",
  //   component: () => import("../views/search.vue"),
  // },
  // {
  //   path: "/info/:id",
  //   name: "info",
  //   component: () => import("../views/info.vue"),
  // },
  // {
  //   path: "/detaile/:id",
  //   name: "detaile",
  //   component: () => import("../views/detaile.vue"),
  // },
  // {
  //   path: "/resetPassword",
  //   name: "resetPassword",
  //   component: () => import("../views/resetPassword.vue"),
  // },
  // {
  //   path: "/updatePassword",
  //   name: "updatePassword",
  //   component: () => import("../views/updatePassword.vue"),
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const AuthorizationFreeList = ["login", "resetPassword"];

router.beforeEach((to, from, next) => {
  if (
    !AuthorizationFreeList.includes(to.name) &&
    !router.app.$options.store.state.userInfo?.token
  ) {
    if (localStorage.getItem("USER_INFO")) {
      router.app.$options.store.commit(
        "SET_USER_INFO",
        JSON.parse(localStorage.getItem("USER_INFO"))
      );
      next();
    } else {
      next("login");
    }
  }
  next();
});

export default router;
